import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServicesStyles from "./services.module.css"
import Footer from "../components/Footer"
const Services = () => {
  return (
    <Layout>
      <SEO title="Services" />
      <h1 className={"hidden"}>Services</h1>
      <div className={`row mb-50 mt-90 ${ServicesStyles.center}`}>
        <div className="col-5 col-sm-hidden">
          <img
            alt="BUSINESS DESIGN"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-1.png"
            srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-1.png 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-1@2x.png 2x"
          />
        </div>
        <div className="col-5 hidden col-sm">
          <img
            alt="BUSINESS DESIGN"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-1-MOBILE.png"
            srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-1-MOBILE.png 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-1-MOBILE@2x.png 2x"
          />
        </div>
        <div className="col-6 col-sm">
          <div className={ServicesStyles.wrapper}>
            <div>
              <div className={ServicesStyles.title}>BUSINESS DESIGN</div>
              <div className={ServicesStyles.text}>
                <p>
                  Business Design is an activity that uses design methodologies,
                  design mindsets, workshops and business tools to solve
                  business challenges. It is customer-centric, works in
                  multidisciplinary teams, includes researches while creating
                  business strategy, and build tangible prototypes.{" "}
                </p>

                <p>
                  We use strategy, analysis, and business modelling as
                  generative design tools, and help organizations turn their
                  biggest, wildest ideas into businesses with long-term
                  viability.
                </p>
              </div>
            </div>

            <div className={ServicesStyles.list}>
              <div className={ServicesStyles.bold}>Including:</div>
              <div>Business Strategy</div>
              <div>Business Model Design</div>
              <div>Business Transformation</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`row mb-50 ${ServicesStyles.center}`}>
        <div className={`col-5  col-sm ${ServicesStyles.second_order}`}>
          <div className={ServicesStyles.wrapper}>
            <div>
              <div className={ServicesStyles.title}>EXPERIENCE DESIGN</div>
              <div className={ServicesStyles.text}>
                <p>
                  Experience Design is a design practice focused on human
                  outcomes. Particularly the level of engagement and
                  satisfaction that the user derives from a product or service
                  and the relevance of the experience to their needs and
                  context. Event experience design helps create a common vision
                  to guide event design and execution, and carry your desired
                  event narrative throughout the entire attendee journey.
                </p>
                <p>
                  We are creating the experience design with a wholistic design
                  approach instead of current approaches. We complete wholistic
                  design by focusing on human interaction. We design experience
                  with iterative, collaborative an measurable.
                </p>
              </div>
            </div>

            <div className={ServicesStyles.list}>
              <div className={ServicesStyles.bold}>Including:</div>
              <div>Event Concept Design</div>
              <div>Event Experience Design</div>
              <div>Event Management</div>
            </div>
          </div>
        </div>
        <div className="col-6 col-sm-hidden">
          <img
            alt="EXPERIENCE DESIGN"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-2.jpg"
            srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-2.jpg 1x,
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-2@2x.jpg 2x"
          />
        </div>
        <div className="col-6 hidden col-sm">
          <img
            alt="EXPERIENCE DESIGN"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-2-MOBILE.png"
            srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-2-MOBILE.png 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-2-MOBILE@2x.png 2x"
          />
        </div>
      </div>
      <div className={`row mb-50 ${ServicesStyles.center}`}>
        <div className="col-5 col-sm-hidden">
          <img
            alt="INTERACTIVE"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-3.jpg"
            srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-3.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-3@2x.jpg 2x"
          />
        </div>
        <div className="col-5 hidden col-sm">
          <img
            alt="INTERACTIVE"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-3-MOBILE.png"
            srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-3-MOBILE.png 1x,https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/services/SERVICES-3-MOBILE@2x.png 2x"
          />
        </div>
        <div className="col-6 col-sm">
          <div className={ServicesStyles.wrapper}>
            <div>
              <div className={ServicesStyles.title}>INTERACTIVE</div>
              <div className={ServicesStyles.text}>
                <p>
                  The advent of digital technologies has turned the traditional
                  way that people communicate, collaborate and do business on
                  its head. With the utilization of modern era intelligence a
                  must, whatever a company’s vision for the future, organization
                  needs to embrace the new world of digital, and it must include
                  digital transformation in order to grow and compete
                  effectively.
                </p>

                <p>
                  In close cooperation with our customers we develop outstanding
                  user experiences and constantly try to improve them, to create
                  an integrated experience of the brand at all digital
                  touchpoints. From strategy, design and communication to
                  complete state-of-the-art implementation.
                </p>
              </div>
            </div>

            <div className={ServicesStyles.list}>
              <div className={ServicesStyles.bold}>Including:</div>
              <div>Web Development</div>
              <div>Mobile App</div>
              <div>AR/VR Applications</div>
              <div>Growth Marketing</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Services
